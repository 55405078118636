import React from "react";
import { CheckIcon } from "@heroicons/react/solid";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useImageForData } from "../../hooks/useAllImages";

const ModelItem = ({
  modelTitle,
  title,
  description,
  modelPagePath,
  linkText,
  features,
  imageName,
}) => (
  <div className="border-t border-gray-200 pt-8 mt-8 md:pt-12 mt-12 xl:grid xl:grid-cols-3 xl:gap-x-8 model">
    <div>
      <h2 className="text-base font-semibold text-amber-600 tracking-wide uppercase">
        {modelTitle}
      </h2>
      <p className="mt-2 text-3xl font-extrabold text-[#06293d]">{title}</p>
      <p className="mt-4 text-lg text-gray-500">{description}</p>
      <Link
        to={modelPagePath}
        className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-600"
      >
        {linkText}{" "}
        <ChevronRightIcon
          className="flex-shrink-0 w-6 h-6 text-amber-300"
          aria-hidden="true"
        />
      </Link>
    </div>
    <div className="mt-4 md:mt-10 md:grid md:grid-cols-2 md:gap-x-8 xl:mt-0 xl:col-span-2 model_description">
      <ul className="divide-y divide-gray-200">
        {features.slice(0, 5).map((feature, featureIdx) =>
          featureIdx === 0 ? (
            <li key={feature} className="py-4 flex md:py-0 md:pb-4">
              <CheckIcon
                className="flex-shrink-0 h-6 w-6 text-green-500"
                aria-hidden="true"
              />
              <span className="ml-3 text-base text-gray-500">{feature}</span>
            </li>
          ) : (
            <li key={feature} className="py-4 flex">
              <CheckIcon
                className="flex-shrink-0 h-6 w-6 text-green-500"
                aria-hidden="true"
              />
              <span className="ml-3 text-base text-gray-500">{feature}</span>
            </li>
          )
        )}
      </ul>
      <div className="model_img mt-4 md:mt-0">
        <GatsbyImage
          image={useImageForData(imageName)}
          className="rounded-lg shadow-lg object-cover object-center w-full max-h-80 h-full"
          alt={title}
        />
      </div>
    </div>
  </div>
);

export default ModelItem;
