import * as React from "react";
import Title from "../elements/Title";

const ModelsSection = ({ title, description, children }) => (
  <section className="bg-white">
    <Title title={title} description={description} />
    <div className="max-w-7xl mx-auto pt-4 px-4 sm:px-6">{children}</div>
  </section>
);

export default ModelsSection;
