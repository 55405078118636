import * as React from "react";

import PageTitle from "../components/elements/PageTitle";
import Header from "../components/sections/Header";
import ModelsSection from "../components/sections/ModelsSection";
import ModelItem from "../components/elements/ModelItem";
import Footer from "../components/sections/Footer";

const ModelsEnPage = () => (
  <div className="bg-white">
    <PageTitle lang={"en"} pageTitle={"Models"} description={""} />
    <Header lang={"en"} />
    <ModelsSection title={"Models"} description={""}>
      <ModelItem
        modelTitle={""}
        title={"Model A - for one cash register"}
        description={
          "Model A is intended for a cash register. Connected via the checkout’s USB port."
        }
        modelPagePath={"/en/models/a"}
        linkText="Read more"
        features={[
          "No external power supply, powered via usb",
          "Can be operated with serial connection for cash registers 5-36 volts",
          "Can also be operated with an external power source, if the serial connection lacks a power supply",
          "Control units with storage from 32,000,000+ records with memories from 8Gb up to 128Gb capacity",
        ]}
        imageName={"posplus_yellow.jpg"}
      />
      <ModelItem
        modelTitle={""}
        title={"Model C - for server and multiple cash registers"}
        description={
          "Model C is intended to be connected to a central server, locally or via the Internet and thus function as a control unit for a number of cash registers."
        }
        modelPagePath={"/en/models/c"}
        linkText="Read more"
        features={[
          "Up to 9000 users",
          "No external power supply, powered via usb",
          "128GB memory for up to 500 million receipts",
          "Small size, 44x27x14mm",
        ]}
        imageName={"posplus_green.jpg"}
      />
      <ModelItem
        modelTitle={""}
        title={"Buffering Unit - for unstable Internet and support multiple cash registers"}
        description={
          "Fiscal data is being sent to the server in realtime. All fiscal data is replicated and kept on the both sides: Buffering Unit and Control Server."
        }
        modelPagePath={"/en/models/buffering-unit"}
        linkText="Read more"
        features={[
          "SD-card that stores fiscal data Aat least 5 years",
          "Can be offline for 48 hours",
          "The Tax Agency will not visit your POS to download fiscal data",
          "Can be connected to local Wi-Fi",
        ]}
      />
    </ModelsSection>
    <Footer lang={"en"} />
  </div>
);

export default ModelsEnPage;
